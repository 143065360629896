@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: "Pretendard Black";
    src: url("/public/fonts/Pretendard-Black.woff") format("woff");
}
@font-face {
    font-family: "Pretendard Bold";
    src: url("/public/fonts/Pretendard-Bold.woff") format("woff");
}
@font-face {
    font-family: "Pretendard ExtraBold";
    src: url("/public/fonts/Pretendard-ExtraBold.woff") format("woff");
}
@font-face {
    font-family: "Pretendard ExtraLight";
    src: url("/public/fonts/Pretendard-ExtraLight.woff") format("woff");
}
@font-face {
    font-family: "Pretendard Light";
    src: url("/public/fonts/Pretendard-Light.woff") format("woff");
}
@font-face {
    font-family: "Pretendard Medium";
    src: url("/public/fonts/Pretendard-Medium.woff") format("woff");
}
@font-face {
    font-family: "Pretendard Regular";
    src: url("/public/fonts/Pretendard-Regular.woff") format("woff");
}
@font-face {
    font-family: "Pretendard SemiBold";
    src: url("/public/fonts/Pretendard-SemiBold.woff") format("woff");
}
@font-face {
    font-family: "Pretendard Thin";
    src: url("/public/fonts/Pretendard-Thin.woff") format("woff");
}

@layer base {
    :root {
      --background: 0 0% 100%;
      --foreground: 222.2 47.4% 11.2%;
      --muted: 210 40% 96.1%;
      --muted-foreground: 215.4 16.3% 46.9%;
      --popover: 0 0% 100%;
      --popover-foreground: 222.2 47.4% 11.2%;
      --border: 214.3 31.8% 91.4%;
      --input: 214.3 31.8% 91.4%;
      --card: 0 0% 100%;
      --card-foreground: 222.2 47.4% 11.2%;
      --primary: 222.2 47.4% 11.2%;
      --primary-foreground: 210 40% 98%;
      --secondary: 210 40% 96.1%;
      --secondary-foreground: 222.2 47.4% 11.2%;
      --accent: 210 40% 96.1%;
      --accent-foreground: 222.2 47.4% 11.2%;
      --destructive: 0 100% 50%;
      --destructive-foreground: 210 40% 98%;
      --ring: 215 20.2% 65.1%;
      --radius: 0.5rem;
    }
   
    .dark {
      --background: 224 71% 4%;
      --foreground: 213 31% 91%;
      --muted: 223 47% 11%;
      --muted-foreground: 215.4 16.3% 56.9%;
      --accent: 216 34% 17%;
      --accent-foreground: 210 40% 98%;
      --popover: 224 71% 4%;
      --popover-foreground: 215 20.2% 65.1%;
      --border: 216 34% 17%;
      --input: 216 34% 17%;
      --card: 224 71% 4%;
      --card-foreground: 213 31% 91%;
      --primary: 210 40% 98%;
      --primary-foreground: 222.2 47.4% 1.2%;
      --secondary: 222.2 47.4% 11.2%;
      --secondary-foreground: 210 40% 98%;
      --destructive: 0 63% 31%;
      --destructive-foreground: 210 40% 98%;
      --ring: 216 34% 17%;
    }
  }
   
  @layer base {
    * {
      @apply border-border;
    }
    body {
      @apply font-sans antialiased bg-background text-foreground;
      overflow: hidden;
      position: fixed;
      width: 100%;
    }
  }

  @layer utilities {
    .no-scrollbar::-webkit-scrollbar {
      display: none; /* Chrome, Safari */
    }
  
    .no-scrollbar {
      -ms-overflow-style: none;  /* IE, Edge */
      scrollbar-width: none;  /* Firefox */
    }
  }

  @supports (-webkit-touch-callout: none) {
    input, textarea, select {
      font-size: 16px !important;
    }
  }

  

.swiper-pagination-bullet {
  width: 5px;
  height: 5px;
  background-color: #ffffffff !important;
  opacity: 0.6;
  border: 2px solid white;
  transition: all 0.3s ease;
}

.swiper-pagination-bullet-active {
  width: 5px;
  height: 5px;
  background-color: white; 
  transform: scale(1.3); 
}

